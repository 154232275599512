import { storage } from '@/plugins/firebase';

const putFile = (file, folder, name) => storage
    .child(`${folder}/${name}`)
    .put(file)
    .then(async snapshot => await snapshot.ref.getDownloadURL());

const getFile = () => storage.child('Profiles/').listAll().then(async res => await res.items[0].getDownloadURL());

export {
    putFile, getFile,
}
